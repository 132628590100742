import React from "react"
import styled from "styled-components"

import { Link } from "./shared"
import * as articleUtil from "../util/article"

const Table = styled.table`
  margin: 2em 0;
  width: 48%;

  * {
    text-align: left;
    vertical-align: top;
  }

  @media only screen and (max-width: 800px) {
    width: 80%;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

const Row = styled.tr`
  border-top: 2px solid;
`

const Cell = styled.td`
  padding: 0.8em 0.8em 0.8em 0;

  &:first-of-type {
    /* text-align: right; */
    white-space: nowrap;
  }
`

const ArticleList = ({ articles }) => {
  if (articles.length === 0) {
    return null
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>Datum</th>
          <th>Titel</th>
        </tr>
      </thead>
      <tbody>
        {articles.map(({ publishDate, title, slug, text }) => {
          const isPost = articleUtil.isPost(text)
          const path = isPost ? `/magazin/#meldung-${slug}` : `/artikel/${slug}`

          return (
            <Row key={slug}>
              <Cell>
                {Intl.DateTimeFormat("de-DE", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                }).format(new Date(publishDate))}
              </Cell>
              <Cell>
                <Link to={path}>{title}</Link>
              </Cell>
            </Row>
          )
        })}
      </tbody>
    </Table>
  )
}

export default ArticleList
