import React from "react"
import { graphql } from "gatsby"
import CountUp from "react-countup"
import styled, { ThemeProvider } from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/contact"
import { RichText } from "../api/contentful"
import ArticleList from "../components/article-list"
import ArticleGrid from "../components/article-grid"
import Project from "../components/project"
import {
  VolumeWhite,
  HouseWhite,
  ModelWhite,
  CloudWhite,
} from "../components/illustrations"
import {
  Image as GatsbyImage,
  Section,
  SectionHeading,
  Pagehead,
  PageTitle,
  CallToAction,
  CallToActionVariant,
  InquiryCallToAction,
} from "../components/shared"
import { contrastSectionTheme } from "../components/theme"

const aspectRatio = 1.43
const OpenerImage = styled(GatsbyImage)`
  display: inline-block;
  width: calc(min(16em, (100vw - 3rem) / 2));
  height: calc(min(16em, (100vw - 3rem) / 2) / ${aspectRatio});

  &:not(:first-child) {
    margin-left: 1rem;
  }

  @media only screen and (min-width: 715px) {
    width: calc(min(16em, (100vw - 16em) / 2));
    height: calc(min(16em, (100vw - 16em) / 2) / ${aspectRatio});
  }

  @media only screen and (min-width: 960px) {
    min-width: 22em;
    height: 16em;
  }
`

const pageheadMaxHeight = `50em`
const PageheadIndex = styled(Pagehead)`
  margin-bottom: 2em;
  padding-bottom: 1em;

  @media only screen and (min-width: 960px) {
    min-height: calc(
      min(
        100vh - ${props => props.theme.headerHeightWide},
        ${pageheadMaxHeight}
      )
    );
  }

  @media only screen and (max-width: 715px) {
    margin: 15vw 0 0 0;
    padding-top: 2rem;

    .gatsby-image-wrapper:first-of-type {
      margin-left: 0;
    }
  }
  @media only screen and (max-width: 666px) {
    /* TODO: */
  }
`

const CallToActionGroup = styled.div`
  position: relative;
  width: 100%;

  @media only screen and (max-width: 480px) {
    padding-bottom: 3em;
  }
`

const JobsCallToAction = styled(CallToActionVariant).attrs(() => ({
  to: "/jobs",
}))`
  position: absolute;
  left: 0;
  bottom: 0;
  margin-left: -2rem;

  @media only screen and (min-width: ${props => props.theme.maxLayoutWidth}) {
    margin-left: 0;
  }
`

const TwoColumns = styled.div`
  columns: auto 2;
  margin: 1em 0;

  @media only screen and (max-width: 800px) {
    columns: auto 1;
    max-width: 80%;
    min-width: 300px;
  }
  @media only screen and (max-width: 666px) {
    columns: auto 2;
    min-width: 100%;
    max-width: 100%;
  }
  @media only screen and (max-width: 480px) {
    columns: auto 1;
  }

  p {
    margin: 0;

    & + & {
      margin-top: 1em;
    }
  }
`

const FigureList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(14em, 1fr));
  justify-content: center;
  justify-items: stretch;
  margin-top: 2em;
  text-align: center;

  @media only screen and (max-width: 480px) {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }
`
const FigureCard = styled.div`
  margin: 1em 0px;
`

const FigureIcon = styled.div`
  @media only screen and (max-width: 666px) {
  }
`

const FigureValue = styled.div`
  align-self: end;
  margin-top: 0.6em;
  margin-bottom: 0.4em;
  font-size: 1.6em;
  font-weight: bold;
`

const FigureKey = styled.div`
  align-self: start;

  &:not(:last-child) {
    @media only screen and (max-width: 666px) {
      margin-bottom: 2em;
    }
  }
`

const Mission = styled.div`
  display: flex;
  padding: 1em 0;

  @media only screen and (max-width: 666px) {
    display: block;
  }
`

const MissionImage = styled.div`
  flex: 1;
  min-height: 20em;
  background-image: url(${props => props.$src});
  background-size: contain;
  background-position: top right;
  background-repeat: no-repeat;
  @media only screen and (max-width: 666px) {
    background-position: top left;
  }
`

const MissionText = styled.div`
  flex: 2;
  padding-left: 2em;

  @media only screen and (max-width: 800px) {
    padding-left: 1em;
  }
  @media only screen and (max-width: 666px) {
    padding-left: 0;
  }
`

const MissionCallToActionContainer = styled.div`
  text-align: right;

  @media only screen and (max-width: 800px) {
    text-align: left;
  }
`

const Performance = styled.div`
  display: flex;
  width: 100%;
`

const PerformanceText = styled.div`
  padding-right: 2em;
  @media only screen and (max-width: 800px) {
    padding-right: 1em;
  }
  @media only screen and (max-width: 666px) {
    padding-right: 0;
  }
`

/* Nur ein Platzhalter, damit die Textkolumnen gleich breit bleiben */
const PerformanceImage = styled.div`
  flex: 1;
`

const CountAnimation = styled(({ value, ...props }) => (
  <CountUp end={value} duration={3} useEasing={true} separator="." {...props} />
))`
  font-variant-numeric: tabular-nums;
`

const IndexPage = ({ data }) => {
  const pageData = data.contentfulHomePage

  const projects = data.allContentfulProject.edges
    .slice(0, 2)
    .map(edge => edge.node)
    .sort((a, b) => a.title.localeCompare(b.title))
  const articles = data.allContentfulArticle.edges
    .map(edge => edge.node)
    .sort((a, b) => -a.publishDate.localeCompare(b.publishDate))

  return (
    <Layout>
      <SEO title={pageData.title} description={pageData.metaDescription} />
      <PageheadIndex>
        <div>
          {pageData.openerImages.map((image, index) => (
            // TODO: alt
            <OpenerImage fluid={image.fluid} alt="" key={index} />
          ))}
        </div>
        <PageTitle>
          <RichText node={pageData.pageTitle} unwrapParagraphs />
        </PageTitle>
        <CallToActionGroup>
          <InquiryCallToAction>
            {pageData.inquiryCallToAction}
          </InquiryCallToAction>
          <JobsCallToAction>{pageData.jobsCallToAction}</JobsCallToAction>
        </CallToActionGroup>
      </PageheadIndex>
      <Section id="magazin" style={{ paddingTop: "3em" }}>
        <SectionHeading>{pageData.magazineTitle}</SectionHeading>
        <ArticleGrid articles={articles.slice(0, 2)} teaser />
        <ArticleList articles={articles.slice(2)} />
        {articles.length > 2 && (
          <CallToAction to="/magazin">
            {pageData.magazineCallToAction}
          </CallToAction>
        )}
      </Section>
      <ThemeProvider theme={contrastSectionTheme}>
        <Section id="leistungen">
          <SectionHeading>{pageData.servicesTitle}</SectionHeading>
          <Performance>
            <PerformanceText>
              <TwoColumns>
                <RichText node={pageData.servicesText} />
              </TwoColumns>
              <CallToAction to="/leistungen">
                {pageData.servicesCallToAction}
              </CallToAction>
            </PerformanceText>
            <PerformanceImage />
          </Performance>
          <FigureList>
            <FigureCard>
              <FigureIcon className="FigureIcon_container">
                <VolumeWhite />
              </FigureIcon>
              <FigureValue>
                <CountAnimation value={8000} />
                &nbsp;m<sup>3</sup>
              </FigureValue>
              <FigureKey>verbautes Holz</FigureKey>
            </FigureCard>
            <FigureCard>
              <FigureIcon className="FigureIcon_container">
                <HouseWhite />
              </FigureIcon>
              <FigureValue>
                <CountAnimation value={400} />
              </FigureValue>
              <FigureKey>geplante Gebäude</FigureKey>
            </FigureCard>
            <FigureCard>
              <FigureIcon className="FigureIcon_container">
                <ModelWhite />
              </FigureIcon>
              <FigureValue>
                <CountAnimation value={1200} />
                &nbsp;GB
              </FigureValue>
              <FigureKey>in digitalen Modellen</FigureKey>
            </FigureCard>
            <FigureCard>
              <FigureIcon className="FigureIcon_container">
                <CloudWhite />
              </FigureIcon>
              <FigureValue>
                <CountAnimation value={(8000 * 700) / 1000} />
                &nbsp;t
              </FigureValue>
              <FigureKey>
                gebundenes CO<sub>2</sub>
              </FigureKey>
            </FigureCard>
          </FigureList>
        </Section>
      </ThemeProvider>
      <Section id="leitbild">
        <SectionHeading>{pageData.missionTitle}</SectionHeading>
        <Mission>
          <MissionImage $src={pageData.missionImage.fluid.src} />
          <MissionText>
            <PageTitle style={{ width: "100%", textAlign: "right" }}>
              {pageData.missionSummary}
            </PageTitle>
            <TwoColumns>
              <RichText node={pageData.missionText} />
            </TwoColumns>
            <MissionCallToActionContainer>
              <CallToAction to="/leitbild">
                {pageData.missionCallToAction}
              </CallToAction>
            </MissionCallToActionContainer>
          </MissionText>
        </Mission>
      </Section>
      <Section id="projekte">
        <SectionHeading>{pageData.projectsTitle}</SectionHeading>
        {projects.map((project, index) => (
          <Project {...project} reversed={index % 2 === 1} key={project.slug} />
        ))}
      </Section>
      <Contact />
    </Layout>
  )
}

export const query = graphql`
  query IndexPageQuery {
    contentfulHomePage {
      title
      metaDescription
      openerImages {
        fluid(maxWidth: 400) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
      pageTitle {
        json
      }
      inquiryCallToAction
      jobsCallToAction
      magazineTitle
      magazineCallToAction
      servicesTitle
      servicesText {
        json
      }
      servicesCallToAction
      missionTitle
      missionImage {
        fluid(maxWidth: 400) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
      missionSummary
      missionText {
        json
      }
      missionCallToAction
      projectsTitle
    }

    allContentfulArticle {
      edges {
        node {
          title
          slug
          publishDate
          author {
            firstName
            lastName
          }
          coverImage {
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          text {
            json
            content {
              content {
                nodeType
                value
              }
              nodeType
            }
          }
        }
      }
    }

    allContentfulProject {
      edges {
        node {
          title
          slug
          description {
            json
          }
          timberVolume
          photos {
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
    }
  }
`

export default IndexPage
